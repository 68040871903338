.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  height: 100vh;
  width: 100%;

  background-color: rgba(black, 0.5);
}