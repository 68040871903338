@import "app/styles/getty/Legacy.module.scss";

.divider {
  margin: 0 0.5rem;
}

.results {
  color: $black;
  font-size: .875rem;
  -webkit-font-smoothing: antialiased;
  margin: 0 6px 0 0;
}

.tooltipPositionWrapper {
  position: relative
}
