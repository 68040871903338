@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  margin-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 0.25rem;

  @media #{$istock-medium-up} {
    padding-left: 1.5rem;
    padding-right: 0.75rem;
  }

  @media #{$istock-large-up} {
    padding-left: 2.25rem;
    padding-right: 1.5rem;
  }

  .header {
    font-weight: 500;
    font-size: 1.325rem;

    margin: {
      top: 0;
      bottom: 0;
    }
  }

  .suggestion {
    margin: {
      top: 1.5rem;
      bottom: 1rem;
    }

    font-weight: bold;
  }

  .text {
    margin-top: 0;

    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }

  .appliedFilterHeader {
    color: $grey;
    font-size: 1.125rem;
    padding-top: 2.5rem;
    padding-bottom: 1rem;
  }

}
