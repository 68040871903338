@mixin galleryAssetVideo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  background: transparent;

  height: 100%;
  width: 100%;
}

.fixedAssetVideo {
  @include galleryAssetVideo;

  -o-object-fit: contain;
  object-fit: contain;
}

.mosaicAssetVideo {
  @include galleryAssetVideo;

  -o-object-fit: cover;
  object-fit: cover;
}

@mixin assetVideoThumbnail {
  position: relative;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.fixedVideoThumbnail {
  @include assetVideoThumbnail;

  -o-object-fit: contain;
  object-fit: contain;
}

.mosaicVideoThumbnail {
  @include assetVideoThumbnail;

  -o-object-fit: cover;
  object-fit: cover;
}
