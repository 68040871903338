@import "app/styles/getty/Legacy.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss";

.relatedTerms {
  display: flex; 
  transition: opacity $complex-duration ease;
  --ratio: calc(var(--width)/var(--height));
  --row-height: 12em;
  flex-basis: calc(var(--ratio)*var(--row-height));
  flex-grow: calc(var(--ratio)*100);
  margin-bottom: .75em;
  margin-right: .75em;

  @media #{$ie11-media-feature} {
    display:flex;
    flex-basis: auto;
    min-height: auto;
    margin: auto;
    padding: 0.30em;
  }

  @media #{$xlarge-up} {
    --row-height: 16em;
  }

  @media #{$xxlarge-up} {
    --row-height: 18em;
  }

  @media screen and (max-width: 415px) {
    min-width: 100% !important;
    height: auto !important;
  }

  &Container {
    padding: 1em;
    width: 100%;
    height: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  &Title {
    padding-bottom: .5rem;
  }
  
  &Content {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    &Grid {
      a {
        margin-bottom: 10px;
      }
    }
  }
}
