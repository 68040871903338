//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_z_index";

$relaxed-queries-z-index: $base-z-index + 2;
$asset-checkmark-z-index: $overlay-z-index + 1;
$clip-badge-z-index: $relaxed-queries-z-index + 1;
$gallery-preview-z-index: $asset-checkmark-z-index + 1;
$action-icon-overlay-z-index: $gallery-preview-z-index + 1;
$gallery-batch-download-banner-z-index: $action-icon-overlay-z-index + 1;
$search-overlay-z-index: $action-icon-overlay-z-index + 1;
$filter-dropdown-z-index: $search-overlay-z-index;
$peeky-search-bar-z-index: $search-overlay-z-index + 1;
$filter-panel-overlay-z-index: $peeky-search-bar-z-index + 1;
$sticky-panel-z-index: $filter-panel-overlay-z-index;
$filter-panel-z-index: $filter-panel-overlay-z-index + 1;
$sticky-filter-dropdown-z-index: $filter-panel-z-index;
$search-filter-modal-z-index: $filter-panel-z-index + 1;
$gallery-overlay-z-index: $search-filter-modal-z-index + 1;
