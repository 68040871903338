@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;

.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.2;
}

.breadcrumb {
  font-size: 0.875rem; // 14px

  &:not(:last-child):after {
    content: '>';
    display: inline-block;
    margin: 0 0.3rem;
    font-weight: 500;
    color: commonStyles.$true-black;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}
