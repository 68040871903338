@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  margin: 1rem 0 0 0;

  @media #{istock-$medium-up} {
    padding: {
      right: 1.5rem;
      left: 1.5rem;
    }
  }
}

.filterPanelContainer {
  padding-top: 10px;
  &::after {
    content: "";
    display: block;
    border-bottom: 1px solid #E3E3E3;
    padding-top: 1.125rem;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.appliedFilter {
  position: relative;
  flex: 0 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 36px;
  max-width: 243px;
  height: 32px;
  margin: {
    bottom: .5rem;
    right: .5rem;
  }
  padding-right: .75rem;
  padding-left: .75rem;

  border: 1px solid $is-grey;
  border-radius: 4px;
  background-color: $is-light-grey;
  color: $grey;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  &:last-child {
    flex: 0 0 auto;
  }

  &Text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
  }

  &ClearAll {
    display: inline-block;
    margin-bottom: .5rem;
    font-size: .75rem;
    font-weight: 500;
    cursor: pointer;
    border: none;

    white-space: nowrap;

    &:hover {
      color: $is-dark-green;
    }
  }

  &Close {
    height: 8px;
    min-width: 8px;
    margin-left: 16px;

    .applied-filter:hover & {
      color: black;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  margin-bottom: .75rem;
  margin-right: 8px;
  font-size: 1rem;
}

.clearButton {
  background: transparent;
}
