@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Zindex.module.scss";

$relaxed-queries-z-index: $base-z-index + 2;

.header {
  color: $grey;
  font-size: 1.125rem;
  margin-top: 2.5rem;
}

.carouselHeader {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
}

.galleryLink {
  margin-left: 2rem;
  z-index: $relaxed-queries-z-index;
}

.carouselContainer {
  display: flex;
  flex-flow: row wrap;
  height: 200px;
  overflow: hidden;
  margin: 1em 0 2.5em 0;
}

.carouselItem {
  height: 200px;
  margin-right: 1em;

  background-color: black;
}

.assetLink {
  display: block;
  height: 100%;
  width: 100%;
}

.assetThumb {
  height: 200px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: .8;
  }
}
