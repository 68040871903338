@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;

.link {
  color: gettyTheme.$primary;

  &:hover {
    color: gettyTheme.$primary-dark;
    text-decoration: none;
  }
}


