@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.preview {
  margin: 30px 0;
}

.previewTitle {
  color: #353838;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 16px;
}
.previewContainer {
  width: auto;
  min-height: 190px;
  margin-right: 10px;
  padding: 20px;
  background-color: #F4F6F6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.imagesContainer {
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-around;
}
.thumb {
  width: auto;
  height: 100%;
  margin: 0 6px;
}

.moreAssetsText {
  color: #02A388;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 16px 20px 30px;
  white-space: nowrap;
}

.arrowRight {
  transform: translateY(2px) rotate(180deg);
  fill: #02A388;
}

// For Undo and Basic Language Correction

.container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  padding-right: 24px;
  background-color: $white;
  border: 1px solid $is-grey;
  border-radius: 4px;
  width: fit-content;
  font-size: 14px;
  min-height: 48px;

  svg {
    flex-shrink: 0;
  }
}

.backText {
  position: relative;
  left: -5px;
}

.content {
  display: inline-block;
  padding-right: 20px;
  span {
    span {
      font-weight: 700;
    }

    a {
      font-weight: 700;
      cursor: pointer;
    }
  }
}
