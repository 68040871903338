@import "app/styles/getty/Legacy.module.scss";

.tooltip {
  width: 250px;
  font-size: 0.75rem;
  line-height: normal;
}

.icon {
  margin-left: 6px;
  fill: $gi-black;
  cursor: pointer;
}