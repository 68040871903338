@import 'app/styles/istock/Zindex.module.scss';

.container {
  position: absolute;
  display: block;
  box-shadow: 0 3px 6px 5px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: #ffffff;
  padding: 1rem;
  border-radius: 6px;

  z-index: $gallery-preview-z-index;

  .header {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;

    .icon {
      display: flex;
      color: rgba(0, 0, 0, 0.7);
      align-self: start;
    }

    .title {
      margin: 0;
      font-size: 1.125rem;
    }
  }

  .caption {
    font-size: 0.75rem;
    line-height: 14px;
  }

  .thumbnail {
    max-height: 340px;
    max-width: 340px;
  }
}
