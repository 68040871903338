@import "app/styles/getty/Legacy.module.scss";
@import "app/styles/getty/Zindex.module.scss";

.container {
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.icon {
  display: block;
  align-self: center;
  height: 25px;
  
}

.tooltip {
  width: 250px;
  font-size: 0.75rem;
  line-height: normal;
  z-index: 9999;
  color: black;
}

.fixedAssetContainer {
  top: 8px;
  right: 0px;
}

.fixedAssetIcon {
  height: 18px;
}

.mosaicZIndex {
  z-index: $action-icon-overlay-z-index + 1;
}

.previewZIndex {
  z-index: $gallery-preview-z-index;
}

.previewContainer {
  top: 25px;
  right: 25px;
}
