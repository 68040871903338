@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "app/styles/istock/Legacy.module.scss";

.container {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 0.5rem;
}

.toggleButton {
  width: 215px;
  height: 44px;
  background: #FFFFFF;
  color: $shale-grey;
  border: 1px solid $some-grey-border;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0 3px $grid-grey;
  }

  &:first-child {
    border-radius: 5px 0 0 5px;
    border-right: 0;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }

  .icon {
    fill: $shale-grey;
  }
}

.toggleButtonSelected {
  @extend .toggleButton;
  background: $shale-grey;
  color: #FFFFFF;

  &:hover {
    box-shadow: none;
  }

  .icon {
    fill: #FFFFFF;
  }
}